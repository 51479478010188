<template>

    <div style="position:absolute;top:40%;left:48%;font-size:50px">404</div>

</template>

<script>
    export default {

    }
</script>

<style>

</style>